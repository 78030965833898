import React, {useCallback, useState} from 'react';
import cx from "classnames";
import styles from "./transaction.module.scss";
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import TransactionFilter from "./components/TransactionFilter";
import TransactionInfo from "./components/TransactionInfo";
import TransactionList from "./components/TransactionList";
import {actions} from "./slice";
import selectState from "./selectors";
import Pagination from "../../common/Pagination";
import { Loading} from "../../common";

const Transactions = () => {
    const dispatch = useDispatch();
    const {transactions, loading} = selectState();
    const [params, setParams] = useState('');

    const handleFilter = useCallback((queryParams='', page=1, size=10) => {
        setParams(queryParams);
        dispatch(actions.getTransactions({account_type: 'main_account', page, size, queryParams}));
    }, [dispatch]);

    const handlePerPageChange = useCallback(( value) => {
        handleFilter(params, 1, value);
    }, [params, handleFilter]);

    const handlePageChange= useCallback((page, perPage) => {
        handleFilter(params, page, perPage);
    }, [params, handleFilter]);

    return (
        <Grid container className={cx(styles.container)}>
            <h1 className={cx(styles.title)}>Transactions</h1>
            <TransactionInfo loading={loading} getTransactions={handleFilter}/>
            <TransactionFilter onFilter={handleFilter} loading={loading}/>
            <Grid container>
                {loading &&  <Loading className={cx(styles.loading)}/>}
                {!loading && transactions?.items?.length === 0 && <h3 className={cx(styles.noData)}>No data found</h3>}
                {!loading && <TransactionList transactions={transactions} />}
                {!loading && <Pagination
                    data={transactions}
                    pageChange={handlePageChange}
                    perPageChange={handlePerPageChange}
                />}
            </Grid>
        </Grid>
    );
};

export default Transactions;
